<template>
    <div class="block">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10,20,50,100]"
        :page-size="10"
        layout="total,sizes,prev, pager, next, jumper"
        :total="pageson">
        <!--  sizes  切换条数 -->
      </el-pagination>
    </div>
  </template>
  <script>
    export default {
        props:{
            pageson:Number
        },
      methods: {
        handleSizeChange(val) {
          this.$emit('row',val)
        },
        handleCurrentChange(val) {
          this.$emit('currentPage',val)

        }
      },
      data() {
        return {
          currentPage: 1
        };
      }
    }
  </script>
<style scoped lang="less">
.block{
  width: 100%;
    text-align: right;
}
</style>